import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { aesEncrypt, LanguagePolisku, useAxios } from "@pru-cnp/ui-polisku";
import * as BuilderLiveness from "@verihubs/liveness";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import FacePerson from "../assets/face-img.jpg";
import { instructionData } from "./FaceIcon";

import { EKYC_COMMON_HOST } from "../root.component";

function FaceStep() {
  const stringParams = document.location.search;
  const searchParams = new URLSearchParams(stringParams);
  let lang = searchParams.get("language");
  const [language] = React.useState(
    lang || window.localStorage.getItem("language") || "id"
  );
  const { echo } = LanguagePolisku();
  const { state } = useLocation();
  const navigate = useNavigate();

  React.useEffect(() => {
    let LIVENESS_SDK_URL =
      "https://polisku-uat.prudential.co.id/ui-ekyc/sdk/liveness";
    if (process.env.NODE_ENV === "production")
      LIVENESS_SDK_URL =
        "https://polisku.prudential.co.id/ui-ekyc/sdk/liveness";
    if (process.env.NODE_ENV === "development")
      LIVENESS_SDK_URL =
        "https://polisku-uat.prudential.co.id/ui-ekyc/sdk/liveness";

    const stringParams = document.location.search;
    const searchParams = new URLSearchParams(stringParams);

    if (window.location.pathname === "/liveness") {
      let livenessId = searchParams.get("livenessId");
      if (livenessId) window.localStorage.setItem("livenessId", livenessId);
      livenessId = window.localStorage.getItem("livenessId");
      const EKYC_COMMON_HEADERS = {
        id: `liveness_ekyc_${livenessId}`,
      };
      useAxios({
        method: "post",
        override: {
          title: "ekyc-get-liveness-detail",
          url: `${EKYC_COMMON_HOST}/liveness-detail`,
          onboardId: `liveness_ekyc_${livenessId}`,
        },
      }).then((response) => {
        if (response) {
          const { status, data } = response;
          if (status == 200) {
            if (data.resp_code == 0) {
              const userEkyc = {
                redirect_url: data.redirect_url,
                token: data.token,
              };
              window.localStorage.setItem(
                "userEkyc",
                aesEncrypt(JSON.stringify(userEkyc))
              );
              EKYC_COMMON_HEADERS.Authorization = `Bearer ${data.token}`;
              try {
                window.LivenessSDK = new BuilderLiveness()
                  .setProxyMiddleware({
                    PassiveLiveness: {
                      url: `${EKYC_COMMON_HOST}/liveness-face-compare`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                    License: {
                      url: `${EKYC_COMMON_HOST}/license-liveness`,
                      headers: EKYC_COMMON_HEADERS,
                    },
                  })
                  .setInstruction(["look_left", "look_right"], {
                    commands: ["open_mouth"],
                    seedLimit: 1,
                  })
                  .setContent({
                    Instruction: {
                      title: "Pengecekan Wajah",
                      subtitle:
                        "Silahkan mengikuti instruksi yang ada pada layar Anda.",
                      action: {
                        start: "Mulai",
                        next: "Lanjutkan",
                      },
                    },
                  })
                  .setTheme({
                    Component: {
                      Instruction: {
                        action: {
                          start: {
                            backgroundColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                          },
                          next: {
                            backgroundColor: "red",
                            hover: {
                              backgroundColor: "red",
                              boxShadow: "0 4px 24px -2px #D3d3d3",
                            },
                          },
                        },
                      },
                    },
                  })
                  .setTimeout(90000)
                  .setDisruptDuration(90000)
                  .setURL(LIVENESS_SDK_URL)
                  .setVirtualCameraLabel(["OBS", "Virtual"])
                  .setScreenOrientation("auto")
                  .setCompressionAlgorithm({
                    dimension: [1440, 1920],
                    qualityThreshold: 100,
                    fileSizeThreshold: 300000,
                  })
                  .build();
              } catch (error) {
                alert(error);
                navigate("/liveness");
              }
            } else {
              navigate("/liveness");
              const responseError =
                language === "id"
                  ? data.resp_desc.Indonesian
                  : data.resp_desc.English;
              alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
              window.localStorage.removeItem("userEkyc");
            }
          } else {
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
            window.localStorage.removeItem("userEkyc");
          }
        }
      });
      return;
    }

    let onBoardId = searchParams.get("onBoardId");
    if (onBoardId) window.localStorage.setItem("onBoardId", onBoardId);
    onBoardId = window.localStorage.getItem("onBoardId");
    const EKYC_COMMON_HEADERS = {
      id: `onboard_ekyc_${onBoardId}`,
    };

    useAxios({
      method: "post",
      override: {
        title: "ekyc-get-onboard-detail",
        url: `${EKYC_COMMON_HOST}/onboard-detail`,
        onboardId: `onboard_ekyc_${onBoardId}`,
      },
    }).then((response) => {
      if (response) {
        const { status, data } = response;
        if (status == 200) {
          if (data.resp_code == 0) {
            const userEkyc = {
              email: data.email,
              phone: data.phone,
              redirect_url: data.redirect_url,
              onBoardId: data.ref_no,
              token: data.token,
            };
            window.localStorage.setItem(
              "userEkyc",
              aesEncrypt(JSON.stringify(userEkyc))
            );
            EKYC_COMMON_HEADERS.Authorization = `Bearer ${data.token}`;

            try {
              window.LivenessSDK = new BuilderLiveness()
                .setProxyMiddleware({
                  PassiveLiveness: {
                    url: `${EKYC_COMMON_HOST}/liveness`,
                    headers: EKYC_COMMON_HEADERS,
                  },
                  License: {
                    url: `${EKYC_COMMON_HOST}/license-liveness`,
                    headers: EKYC_COMMON_HEADERS,
                  },
                })
                .setInstruction(["look_left", "look_right"], {
                  commands: ["open_mouth"],
                  seedLimit: 1,
                })
                .setContent({
                  Instruction: {
                    title: "Pengecekan Wajah",
                    subtitle:
                      "Silahkan mengikuti instruksi yang ada pada layar Anda.",
                    action: {
                      start: "Mulai",
                      next: "Lanjutkan",
                    },
                  },
                })
                .setTheme({
                  Component: {
                    Instruction: {
                      action: {
                        start: {
                          backgroundColor: "red",
                          hover: {
                            backgroundColor: "red",
                            boxShadow: "0 4px 24px -2px #D3d3d3",
                          },
                        },
                        next: {
                          backgroundColor: "red",
                          hover: {
                            backgroundColor: "red",
                            boxShadow: "0 4px 24px -2px #D3d3d3",
                          },
                        },
                      },
                    },
                  },
                })
                .setTimeout(90000)
                .setDisruptDuration(90000)
                .setURL(LIVENESS_SDK_URL)
                .setVirtualCameraLabel(["OBS", "Virtual"])
                .setScreenOrientation("auto")
                .setCompressionAlgorithm({
                  dimension: [1440, 1920],
                  qualityThreshold: 100,
                  fileSizeThreshold: 300000,
                })
                .build();
            } catch (error) {
              alert(error);
              navigate("/ekyc");
            }
          } else {
            navigate("/ekyc");
            const responseError =
              language === "id"
                ? data.resp_desc.Indonesian
                : data.resp_desc.English;
            alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
            window.localStorage.removeItem("userEkyc");
          }
        } else {
          const responseError =
            language === "id"
              ? data.resp_desc.Indonesian
              : data.resp_desc.English;
          alert(responseError || "Sistem mengalami gangguan, mohon coba beberapa saat lagi.");
          window.localStorage.removeItem("userEkyc");
        }
      }
    });
  }, []);

  return (
    <>
      <Title>{echo("face-recognition")}</Title>
      <Box
        sx={(theme) => ({
          display: "none",
          [theme.breakpoints.down("lg")]: {
            display: "flex",
          },
        })}
      >
        <FaceImage src={FacePerson} alt="Face recognition" />
      </Box>
      <Container>
        <Subtitle>{echo("please-make-sure")}:</Subtitle>
        {instructionData.map((item, index) => (
          <ListItem key={index}>
            <IconWrapper>
              <Icon src={item.icon} alt={`Icon ${index + 1}`} />
            </IconWrapper>
            <ListText>{echo(item.text)}</ListText>
          </ListItem>
        ))}
      </Container>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Subtitle = styled.h2`
  color: #000;
  margin: 20px 0;
  width: 100%;
  font: 700 18px/156% Open Sans, sans-serif;
`;

const ListItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-right: 20px;
  gap: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--Primary-Prudential-red, #ed1b2e);
  padding: 8px;
`;

const Icon = styled.img`
  width: 14px;
  stroke: var(--Primary-White-primary, #);
  border: 0px solid rgba(255, 255, 255, 1);
`;

const ListText = styled.p`
  color: var(--Primary-Black-primary, #);
  margin: auto 0;
  font: 400 16px/150% Open Sans, sans-serif;

  .italic {
    font-style: italic;
  }
`;

const ImageWrapper = styled.div`
  align-self: stretch;
  max-width: 308px;
`;

const Image = styled.img`
  aspect-ratio: 1.54;
  object-fit: cover;
  object-position: center;
  width: 100%;
`;

const Title = styled.h2`
  color: var(--Primary-Prudential-red, #ed1b2e);
  font: 700 20px/140% Open Sans, sans-serif;
`;

const FaceImage = styled.img`
  aspect-ratio: 1.45;
  object-fit: cover;
  width: 100%;
  margin-top: 20px;
`;

const Instruction = styled.p`
  color: var(--Primary-Black-primary, #000);
  font: 400 16px/24px Open Sans, sans-serif;
`;

export default FaceStep;
